

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


export default function JawFillerfaq({setIsModalOpen}) {
    return (
      <>
      <Separator />
      
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-center pb-5">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about <span>Jaw filler</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What does the Jaw Filler Treatment London involve? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The treatment is carried out by injecting dermal fillers strategically to the jaw or jowl area,
                        with a cannula/needle, delivering results that are desirable and suitable for the patient.
                        Treatment results are instant and the treatment time typically lasts between 10-15 minutes.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What happens during Jaw Filler Treatment London? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We start the appointment with a thorough consultation to understand your requirements and
                        suitability. Once we have a full understanding and you’re happy to proceed, we start the
                        treatment.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How long does the Jaw Filler London take? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Jaw Filler Treatment takes no longer than 15 minutes; patients will see immediate results, with
                        no recovery time needed, and you can get back to your daily activities or work the very same day

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Does Jaw Filler Treatment hurt? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Numbing cream is applied to the area to be injected and only when it is numbed, the treatment is
                        done, making your experience as comforting as possible. Based on all our previous patients, the
                        pain was mild and some patients felt no pain at all.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Is Jaw Filler Treatment UK safe? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes. Hyaluronic acid based dermal fillers are derived naturally and not animal based. It is
                        known as one of nature’s most versatile and is made with extensive research in the laboratory.

                        Over time the fillers get dissolved naturally in the skin as the body is able to break the
                        filler down and results of the treatment gradually fade. The filler breaks down and is
                        re-absorbed by the body, just like the natural occurring hyaluronic acid in the skin. Hyaluronic
                        acid has gained a reputation for being safe, effective and a good alternative to surgery.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Col>
              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How much is Jaw Filler treatment UK?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At Dermamina London prices are dependent on quantity chosen:<br />

                        1ml - £340<br />
                        2ml - £680<br />
                        3ml - £850<br />
                        4ml – £1090<br /><br />

                        If you are unsure of the quantity to choose from, you can drop our friendly team a message so we
                        can assess and advice you accordingly.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How do I prepare for the Jaw Filler treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It is best to arrive for your appointment with your skin as best prepared for the treatment.
                        This includes, less makeup on the face, plenty of rest the night before so you are calm and
                        relaxed during the treatment, hydrated and had plenty to eat. <br /><br />

                        Please arrive on time, with all online forms completed and with realistic expectations. Dermal
                        fillers are not intended to completely change your appearance, but enhance what you already
                        have.<br /><br />

                        As soon as you book you will be sent pre care advise, included in your form, to help you prepare
                        for the treatment. Our pre care advice includes:<br /><br />

                        - Avoid taking any blood thinning medications such as aspirin and ibuprofen for one week prior
                        to your appointment. This is to prevent bruising during and after the treatment. Please consult
                        with your medical practitioner before stopping any medication<br /><br />

                        - Avoid booking if you had any major dental treatments or plan to after the treatment. This
                        includes root canal extraction/treatment and deep filing. After 2 weeks it will be
                        fine<br /><br />

                        - Arnica tablets can be taken before and after the treatment to reduce the risk of bruising
                        (optional)<br /><br />

                        - To lessen the risk of bleeding and bruising, do not consume alcohol 2-3 days prior or
                        following your appointment


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What should I avoid after?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It is critical that all patients follow the post-treatment instructions and report any adverse
                        effects or concerns that they may have. Post-treatment instructions will be included in the
                        forms sent to your email once a treatment has been scheduled to inform and assist you with any
                        necessary preparations. <br /><br />

                        To remind you, an after-care email is issued immediately after the treatment. <br /><br />We
                        make sure you're safe during and after your treatment at Dermamina London. Post treatment advice
                        includes:<br /><br />
                        - Avoid strenuous exercise immediately following for 24 hours<br />
                        - Avoid touJawg/ rubbing areas injected, avoid applying any pressure on treated areas<br />
                        - Avoid alcohol consumption 24 hours before and after <br />
                        - Avoid applying makeup for 12 hours <br />
                        - Avoid other treatments for 10-14 days such as microdermabrasion, laser treatment, facials etc.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What If I am unhappy with the result?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Jaw filler treatment is great for someone who wants to try out alterations without
                        committing to surgery or totally modifying the Jaw with surgery, rather it allows the
                        restoration and enhancement of the natural beauty while boosting your own collagen in your body.
                        <br /><br />
                        With the administration of hyaluronidase, the treatment is totally reversible. This implies the
                        Jaw can be restored to its normal shape in a matter of days. For those patients that like the
                        result of the treatment, it can be topped up safely over the years to maintain the result.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Will I get a consultation before the treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the appointment will begin with a private consultation that includes a full facial
                        assessment and discussion on the treatment, with what to expect during and after the treatment
                        explained. Then a tailored treatment plan is devised.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What if I change my mind after the consultation?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In the case of changing your mind after the consultation, you will be charged £20 for the
                        consultation and the remaining will be refunded back to the account you made the payment in.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Am I suitable for the Non Surgical Jaw Filler Treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At Dermamina, we strive for natural looking results, tailored to individual suitability, aiming
                        to enhance your beauty rather than completely transforming your look.<br /><br />

                        We suggest booking in for a consultation if you are unsure whether the treatment will help
                        achieve the results you are looking for.<br /><br />

                        You may also get in contact with a member of team and explain your concern with explanations of
                        your Jaw shape along with what you wish to achieve. It is important to speak with the
                        practitioner during the consultation about your expectations in order to confirm whether or not
                        they are achievable.<br /><br />

                        All patients must be 18+.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Can I have other dermal filler treatments along with this treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, more than one dermal filler treatments can be done all in one day, upon assessment and
                        suitability. Did you know that we offer treatment packages? You can choose to add certain amount
                        of filler in the desired areas all in one session with professional and honest advice from our
                        lovely practitioner.<br /><br />

                        Popular options along with this treatment is the lip and nose filler treatments.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Jaw Filler Treatment in London? Why choose Dermamina London?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We are renowned for providing natural looking yet life changing results, and being completely
                        honest with our patients. If we feel a treatment is not suitable for you, we will not recommend
                        for you to go ahead with it.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>

            </Row>


            <br /> <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active">
              <Bookbutton>Book appointment</Bookbutton>
            </div>


          </Container>
        </Section>


        <SeparatorEnd />
      </>

    );
}







